import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Haccp = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { haccp } = useTranslations();

	return (
		<>
			<article className="two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{haccp}</h2>
					<div className="content-container  catchall">
						<h2>
						感染症対策製品特集
						</h2>
						<p className="date">2024年10月1日作成</p>
						<p>
						感染症対策に最適な測定機器をご紹介します。
                        <br/>
                        施設、学校、飲食店、オフィス、工場、ご家庭などでの感染症予防にご活用ください。
						<br/><br/>
						</p>
						<p>
						製品名をクリックすると、各製品の詳細ページを表示します。
						<br/>
						また、製品をまとめたパンフレットは
						<a className="link" href="https://static.sksato.co.jp/leaflet/ja/2024influenza.pdf" target={"_blank"} rel="noreferrer">
						こちら
						</a>
						をご覧ください。<br/><br/>
						</p>

						<div className="checklist">
						    <h6>＜換気＞</h6>
							<p>
							換気の悪い密閉空間では感染症のクラスター発生のリスクが高くなります。<br/>
							CO2（二酸化炭素）濃度の測定は、多人数が利用する空間における不十分な換気を明らかにするために効果的な方法です。
    						</p>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1737-00" target={"_blank"} rel="noreferrer">	
		    					      No.1737-00 快適ナビ CO2モニター SK-50CTH
									  </a>
									  <p>
									  CO2濃度に応じてLEDランプが３段階に変化し、ブザーを鳴らして換気タイミングの目安を的確にお知らせ
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1737-00_X01.jpg"
										height="300"
										width="300"
										alt="SK-50CTH"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1738-00" target={"_blank"} rel="noreferrer">	
									  No.1738-00 快適ナビ CO2モニターミニ SK-40CTH
									  </a>
									  <p>
									  室内の二酸化炭素（CO2）濃度、温度、湿度を測定して、換気タイミングの目安をお知らせ
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1738-00_M01.png"
										height="300"
										width="300"
										alt="SK-40CTH"
									/>								
								</div>
							</div>
							<p>
                            　紹介動画
                            </p>
							<div class="youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/qh118N1o0h8?si=rn1yFVPOYLcxumGH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
					    </div>

					    <div className="checklist">
						<h6>＜手洗い＞</h6>
						    <p>
							外出先からの帰宅時、調理の前後、食事前などは、感染症予防に手洗いがとても重要です。<br/>
							手洗いによる除菌効果は、石鹸と水による場合15秒で付着菌数が約10分の1、30秒では約100万分の1というように、手洗い時間に比例して減少することが確認されています。<br/>
							しかし現実には忙しい時間帯などは洗浄不足に陥るケースが多く、感染を引き起こす原因の一つとなっています。<br/>
							「ノータッチタイマー」は、手をかざすだけ（非接触）でカウントダウンを開始するタイマーです。<br/>
							時間を計って効果の高い手洗いをしましょう。
   						    </p>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1707-20" target={"_blank"} rel="noreferrer">	
		    					      No.1707-20　ノータッチタイマー手洗い当番　TM-27
									  </a>
									  <p>
									  手をかざすだけ（非接触）でカウントダウンを開始する手洗い専用タイマー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1707-20_M01.png"
										height="300"
										width="300"
										alt="TM-27"
									/>								
								</div>

								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1707-30" target={"_blank"} rel="noreferrer">	
		    					      No.1707-30　ノータッチタイマー　TM-29
									  </a>
									  <p>
									  手をかざすだけ（非接触）でカウントダウンを開始する、防滴タイプのタイマー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1707-30_M01.jpg"
										height="300"
										width="300"
										alt="TM-29"
									/>								
							    </div>
						    </div>
							<p>
                            　紹介動画
                            </p>
							<div class="youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/k9Zis3ixlA4?si=JIZMMRHzfF_RNGVP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
						</div>

						<div className="checklist">
						<h6>＜湿度管理＞</h6>
						    <p>
							感染症対策・カゼの予防には室内の湿度管理も重要です。<br/>
							湿度は50％以上を保ちましょう。60％が目標です。<br/>
							外出時や就寝時に50％i簡保場合は、マスクや加湿器などを利用しましょう。
   						    </p>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1074-10" target={"_blank"} rel="noreferrer">	
		    					      No.1074-10　デジタル温湿度計 PC-5410TRH	
									  </a>
									  <p>
										大きな文字表示の卓上・壁掛け用デジタル温湿度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1074-10_M01.png"
										height="300"
										width="300"
										alt="PC-5410TRH"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1051-00" target={"_blank"} rel="noreferrer">	
		    					      No.1051-00　デジタル温湿度計 快適ナビプラス<br/> PC-5500TRH 
									  </a>
									  <p>
									  絶対湿度・暑さ指数から温度・湿度の目安値を表示して、1年を通して快適環境へナビゲート
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1051-00_M01.jpg"
										height="300"
										width="300"
										alt="SK-5500TRH"
									/>								
								</div>
						    </div>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1031-00" target={"_blank"} rel="noreferrer">	
		    					      No.1031-00　温湿度計ミルノEX（ホワイト）
									  </a>
									  <p>
									  湿度目盛に「カゼ注意」「快適湿度」「食中毒注意」の表示付き
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1031-00_E01.png"
										height="300"
										width="300"
										alt="ミルノEX"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1014-00" target={"_blank"} rel="noreferrer"> 	
		    					      No.1014-00　ソフィア温湿度計
									  </a>
									  <p>
									  クリアな文字板が見やすい温湿度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1014-00_M01.jpg"
										height="300"
										width="300"
										alt="ソフィア"
									/>								
								</div>
						    </div>
							<p>
                            　紹介動画
                            </p>
							<div class="youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/lHfxyPtV-Ok?si=d0gq4HRWpDwXaRii" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
						</div>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Haccp;
